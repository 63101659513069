<script setup>
import RatingIcon from "@/components/ui/icons/order-review/RatingIcon.vue";
import vue3starRatings from "vue3-star-ratings";

const ratingStarComponent = RatingIcon;

const nuxtApp = useNuxtApp();

const props = defineProps(['modalId', 'orderId']);

const config = useRuntimeConfig();

const slotRef = ref(null);
const rating = ref(0);
const selected = ref([]);
const comment = ref(null);
const showOtherForm = ref(false);
showOtherForm.value = false;
const submitting = ref(false);
const submitted = ref(false);
const submitError = ref(null);

const variants = {
    "pack": "Удобство упаковки",
    "temperature": "Температура еды",
    "quality": "Вкус и качество блюд",
    "photo-view": "Вид товара отличается от фото в меню",
    "speed": "Скорость доставки",
    "communications": "Коммуникация с курьером",
};

nuxtApp.hook("page:finish", () => {

    if (process.client) {
        setTimeout(() => {
            if (slotRef.value != null) {
                slotRef.value.classList.add("show");
            }

        }, 50);
    }
});

const close = () => {
    nuxtApp.$modalsBus.$emit(`modals.review-popup:close`);
};

const updateRating = (value) => {
    rating.value = Math.max(1, Math.ceil(value));
};

const select = (key) => {
    let selectedValue = selected.value;
    const index = selectedValue.indexOf(key);

    if (index >= 0) {
        selectedValue.splice(index, 1);
    } else {
        selectedValue.push(key);
    }

    selected.value = selectedValue;
};

const submit = () => {
    if (submitted.value || submitting.value) {
        return;
    }

    submitting.value = true;

    useDFetch(`/orders/${props.orderId}/review`, {
        method: 'POST',
        body: {
            selected: selected.value,
            other_text: comment.value,
            rating: rating.value
        }
    }).then((res) => {
        submitting.value = false;
        submitted.value = true;
    }).catch((error) => {
        submitting.value = false;
        submitError.value = error.response?.data?.message ?? "Ошибка, попробуйте отправить позднее";

        setTimeout(() => {
            submitError.value = null;
        }, 2000);
    });
}

</script>
<template>
    <DModal @close="close" class="review-d-modal" :class="{ large: rating > 0 && !submitted }">
        <div class="review-popup">
            <div @click="close" class="close-button"><i class="bi bi-x"></i></div>
            <div class="order-review">
                <div v-if="!submitted" class="d-flex flex-column">
                    <div v-if="rating == 0" class="d-flex flex-column">
                        <div class="order-review__icon">
                            <UiIconsOrderReviewStarIcon />
                        </div>
                        <div class="order-review__title">
                            10 баллов за отзыв!
                        </div>
                        <div class="order-review__text">
                            Помогите нам стать лучше
                        </div>
                    </div>
                    <div class="order-review__rating">
                        <ClientOnly>
                            <vue3starRatings :star-size="50"
                                :star-color="config.public.isDomino ? '#0073AB' : '#FCED00'" inactive-color="#F1F1F1"
                                :custom-svg="ratingStarComponent" :model-value="rating"
                                @update:model-value="updateRating">
                            </vue3starRatings>
                        </ClientOnly>
                    </div>
                    <div class="order-review__help">
                        Оцените ваш последний заказ
                    </div>
                    <div v-if="rating > 0 && !showOtherForm" class="order-review__content">
                        <h2>{{ rating == 5 ? 'Спасибо! Что вам понравилось?' : 'Над чем поработать?' }}</h2>
                        <div class="order-review__content-variants">
                            <div v-for="key in Object.keys(variants)" @click="select(key)" class="variants__item"
                                :class="{ active: selected.includes(key) }">
                                {{ variants[key] }}
                            </div>
                        </div>
                        <div class="order-review__content-other">
                            <a href="#" @click.prevent="showOtherForm = true">Свой вариант</a>
                        </div>
                    </div>
                    <div v-else-if="rating > 0 && showOtherForm" class="order-review__other-form">
                        <h2>Свой вариант</h2>
                        <p>Здесь вы можете оставить подробный отзыв о вашем заказе</p>

                        <textarea v-model="comment" class="form-control" rows="3" />
                    </div>
                    <div class="mt-3">
                        <DButton @click="submit" :loading="submitting">Отправить</DButton>

                        <div class="mt-3">
                            {{ submitError }}
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="order-review__icon">
                        <UiIconsOrderReviewRewardIcon />
                    </div>
                    <div class="order-review__title">
                        10 баллов ваши!
                    </div>
                    <div class="order-review__text">
                        Спасибо за ваш отзыв
                    </div>
                    <div class="mt-3">
                        <DButton @click="close">Вернуться к покупкам</DButton>
                    </div>
                </div>
            </div>
        </div>
    </DModal>
</template>

<style lang="scss">
.review-d-modal {
    &.large {
        .d-modal .d-modal-container .d-modal-content {
            max-width: 824px;
        }
    }

    .d-modal {
        .d-modal-container {

            &::after {
                content: "";
                max-height: 40px;

                @media screen and (max-width: 760px) {
                    content: none;
                }
            }

            .d-modal-content {
                max-width: 460px;
            }
        }

    }
}

.review-popup {
    background-color: #fff;
    border-radius: 20px;
    padding: 30px 20px;

    @if not $is-domino {
        box-shadow: none;
        border: 1px solid #000;
    }

    .order-review {
        display: flex;
        flex-direction: column;
        text-align: center;

        &__other-form {
            margin-top: 40px;

            h2 {
                color: #000;
                text-align: center;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin-bottom: 0;
            }

            p {
                color: #000;
                text-align: center;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }

        &__content {
            margin-top: 40px;

            h2 {
                color: #000;
                text-align: center;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }

            &-other {
                margin-top: 20px;

                a {
                    color: $primary;
                    text-align: center;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-decoration: none;
                }
            }

            &-submit {
                margin-top: 32px;
            }

            &-variants {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                gap: 12px;

                .variants__item {
                    padding: 12px 40px;
                    border-radius: 15px;
                    background-color: #fff;
                    border: 1px solid #B6B6B6;
                    cursor: pointer;
                    opacity: 1;
                    transition: all 0.2s;
                    user-select: none;

                    color: #000;
                    text-align: center;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    &.active {
                        background-color: $primary;
                        border: 1px solid $primary;
                        color: #fff;

                        @if not $is-domino {
                            box-shadow: none;
                            border: 1px solid #000;
                            color: #000;
                        }

                        &:hover {
                            opacity: 1;
                            transition: all 0.2s;
                        }
                    }

                    &:hover {
                        opacity: 0.6;
                        transition: all 0.2s;
                    }
                }
            }
        }

        &__title {
            margin-top: 10px;
            color: #000;
            text-align: center;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        &__text {
            color: #000;
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        &__rating {
            margin-top: 20px;
            margin-left: auto;
            margin-right: auto;
        }

        &__help {
            margin-top: 10px;
            color: #B6B6B6;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-align: center;
        }
    }

    .close-button {
        position: absolute;
        right: 4px;
        top: 4px;

        width: 40px;
        height: 40px;

        padding: 4px;
        font-size: 30px;
        line-height: 0;
        text-align: center;
        cursor: pointer;
        z-index: 100;

        i {
            display: block;
            position: relative;
            top: 50%;
            transform-origin: center;
            transform: scale(1) translate(0, -50%);
        }
    }

}
</style>