<template>
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_992_20342)">
            <path
                d="M27.1801 10.5397C26.5151 9.77317 25.5514 9.33333 24.5353 9.33333H24.4349C23.8528 4.09033 19.3961 0 14.0003 0C8.60442 0 4.14775 4.09033 3.56559 9.33333H3.50025C2.48525 9.33333 1.52275 9.77317 0.856588 10.5397C0.190421 11.3062 -0.108246 12.3223 0.0352542 13.3292L1.27309 21.9905C1.76309 25.417 4.74275 28 8.20309 28H19.8336C23.2939 28 26.2736 25.4158 26.7636 21.9905L28.0014 13.3292C28.1449 12.3235 27.8451 11.3073 27.1801 10.5397ZM14.0003 2.33333C18.1069 2.33333 21.5148 5.38183 22.0829 9.33333H5.91759C6.48575 5.38183 9.89359 2.33333 14.0003 2.33333ZM25.6914 12.999L24.4536 21.6603C24.1269 23.9447 22.1413 25.6678 19.8336 25.6678H8.20309C5.89425 25.6678 3.90859 23.9447 3.58309 21.6603L2.34525 12.999C2.29625 12.6595 2.39425 12.3293 2.61825 12.0692C2.84342 11.809 3.15609 11.6667 3.49909 11.6667H24.5353C24.8783 11.6667 25.1909 11.809 25.4173 12.0692C25.6413 12.3293 25.7404 12.6595 25.6914 12.999ZM15.1669 15.1667V22.1667C15.1669 22.8118 14.6454 23.3333 14.0003 23.3333C13.3551 23.3333 12.8336 22.8118 12.8336 22.1667V15.1667C12.8336 14.5215 13.3551 14 14.0003 14C14.6454 14 15.1669 14.5215 15.1669 15.1667ZM21.0003 15.1667V22.1667C21.0003 22.8118 20.4788 23.3333 19.8336 23.3333C19.1884 23.3333 18.6669 22.8118 18.6669 22.1667V15.1667C18.6669 14.5215 19.1884 14 19.8336 14C20.4788 14 21.0003 14.5215 21.0003 15.1667ZM9.33359 15.1667V22.1667C9.33359 22.8118 8.81209 23.3333 8.16692 23.3333C7.52175 23.3333 7.00025 22.8118 7.00025 22.1667V15.1667C7.00025 14.5215 7.52175 14 8.16692 14C8.81209 14 9.33359 14.5215 9.33359 15.1667Z"
                fill="#0073AB" />
        </g>
        <defs>
            <clipPath id="clip0_992_20342">
                <rect width="28" height="28" fill="white" />
            </clipPath>
        </defs>
    </svg>

</template>