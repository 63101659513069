<script setup>
const device = useDevice();

const props = defineProps(['banners']);
const inited = ref(false);
const isMobile = ref(device.isMobile);
const height = ref(600);
const mounted = ref(false);
onMounted(() => {
    if (process.client) {
        mounted.value = true;
        height.value = Math.round(Number(window.innerWidth / 1.5 * 10 / 31));
        isMobile.value = window.innerWidth < 760;
    }
});

const goToLink = (slide) => {
    if (slide.link) {
        if (slide.link.includes('event:')) {
            useNuxtApp().$modalsBus.$emit(slide.link.slice(6));
        } else if (slide.link.includes('http://') || slide.link.includes('https://')) {
            location.href = slide.link;
        } else {
            useRouter().push(slide.link);
        }
    }
};

const onInit = () => {
    inited.value = true;
};
</script>

<template>
    <div v-if="banners && mounted" class="banner-carousel" :class="{ mobile: isMobile, 'no-inited': !inited }">
        <Swiper :modules="[SwiperAutoplay, SwiperNavigation]" :navigation="true" :slidesPerView="!isMobile ? 1.5 : 1.2"
            :centeredSlides="true" :loop="true" :spaceBetween="20" :slideToClickedSlide="true"
            :autoplay="{ delay: 5000, disableOnInteraction: true }" @init="onInit">
            <SwiperSlide v-for="slide in banners" :key="slide.name" @click="goToLink(slide)" class="text-center">
                <img class="slider-image" :src="`${!isMobile ? slide.url : (slide.url_alt ?? slide.url)}`" alt=""
                    :style="!inited ? `width:66%;height:${height}px;` : ''">
            </SwiperSlide>
        </Swiper>
    </div>
</template>

<style lang="scss" scoped>
.banner-carousel {
    user-select: none;
    margin-bottom: 30px;
    margin-top: 30px;

    @media screen and (max-width: 577px) {
        margin-bottom: 8px;
    }

    &.no-inited {
        height: 315px;
        // visibility: hidden;

        .swiper-wrapper {
            text-align: center;
            margin: auto;
            // transform: translateX(-300px)
        }

        .swiper-slide {

            margin: auto;
            text-align: center;

            .slider-image {
                text-align: center;
                margin: auto;
            }
        }

    }

    // .no-init {
    //     .slider-image {
    //         visibility: hidden;
    //     }
    // }

    .slider-image {
        display: block;
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        pointer-events: none;
        background-size: cover;
        border-radius: 20px;
    }
}
</style>

<style lang="scss">
.banner-carousel {
    // .swiper {
    //     overflow: visible;
    // }

    .swiper-slide {
        background: none !important;
        aspect-ratio: 31 / 10;
        cursor: pointer;

        // max-width: 776px;
    }

    .swiper-slide-active {
        background: none !important;
        background-image: none !important;
        background-color: none !important;
    }

    .swiper-pagination-bullets {
        &.swiper-pagination-horizontal {
            bottom: -25px;
        }

        .swiper-pagination-bullet {
            background-color: #D9D9D9;
            border: 1px solid #9D9D9D;
            opacity: 1;

            &.swiper-pagination-bullet-active {
                background-color: $primary;
                border: 1px solid #000;
            }
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        width: 35px;
        height: 35px;
        background-color: #fff;
        border-radius: 35px;
        border: 1px solid #fff;

        &::after {
            font-size: 20px;
            color: #000;
        }

        @media screen and (max-width: 768px) {
            display: none;
        }
    }

    &.mobile {
        .swiper-slide {
            aspect-ratio: 50 / 23;
        }
    }
}
</style>